import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../../components/Page';
import { Stack, IconButton, Button } from '@mui/material'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import Iconify from '../../components/Iconify';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useFlowService from '../../services/useFlowService'
import SmartForm from '../../components/smart-form/smart-form.component';
import useNotify from '../../common/useNotify';
import LinearProgress from '@mui/material/LinearProgress';
import { useTranslation } from "react-i18next";
import ReactFlow, { addEdge, applyEdgeChanges, applyNodeChanges } from 'react-flow-renderer';
import { useTheme } from '@mui/material/styles';

const FlowEdit = () => {

  const [loading, setLoading] = useState(true);
  const [deletingStep, setDeletingStep] = useState(false);
  const [flow, setFlow] = useState({});
  const [types, setTypes] = useState([]);
  const { id } = useParams()
  const navigate = useNavigate();
  const { success, exception } = useNotify();
  const { getFlow, saveFlow } = useFlowService();
  const { t } = useTranslation();
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [currentNode, setCurrentNode] = useState();
  const [selectedStep, setSelectedStep] = useState();

  const theme = useTheme();

  const colWidth = 180;
  const rowHeight = 100;

  useEffect(() => {
    setTypes([{ id: 'TypeA', name: 'Type A' }, { id: 'TypeB', name: 'Type B' }]);
    getData();
  }, []);

  const addNodes = (nodes, steps, level, col, edges) => {

    steps.forEach((step, stepIndex) => {

      const x = col ?? (stepIndex * colWidth);
      const y = level * rowHeight;

      if (step.rules?.length) {
        level = level + 1;
      }

      const isOver = currentNode === step.code;
      const style = selectedStep?.code == step.code ? { background: theme.palette.primary.lighter } : null;

      const node = {
        id: step.code,
        data: {
          label: <div sx={style}>
            {!isOver
              ? <Typography>{step.code}</Typography>
              : <IconButton onClick={() => setSelectedStep({ oldCode: step.code, ...step })} size="small" >
                <Iconify icon="eva:edit-fill" />
              </IconButton>}
          </div>,
          step: step,
        },
        style: style,
        position: { x: x, y: y },
      };

      step.col = x;

      if (step.type === "Welcome") {
        node.type = 'input';
      }

      if (step.type === "Ending") {
        node.type = 'output';
      }

      nodes.push(node);
    });

    steps.forEach(step => {
      if (step.defaultAction?.type === "GoTo") {
        const nextStep = flow.steps.find(s => s.code === step.defaultAction.value);
        if (nextStep) {
          addNodes(nodes, [nextStep], level + 1, step.col, edges);
          edges.push({ id: `e${step.code}-${nextStep.code}`, source: step.code, target: nextStep.code });
        }
      } else if (step.rules?.length > 0) {
        const steps = flow.steps.filter(s => step.rules.some(r => r.action.type === "GoTo" && r.action.value === s.code));
        addNodes(nodes, steps, level + 1, null, edges);

        step.rules.forEach(rule => {
          const nextStep = steps.find(s => s.code === rule.action.value);
          const label = rule.conditions.map(c => `${c.baseValue} ${t(c.type)} ${c.checkValue}`).join('\n');
          edges.push({ id: `e${step.code}-${nextStep.code}`, source: step.code, target: nextStep.code, animated: true, label: label });
        });
      }
      else if (step.type != "Ending") {
        const nextStepIndex = flow.steps.findIndex(s => s.code === step.code) + 1;
        const nextStep = flow.steps.length > nextStepIndex ? flow.steps[nextStepIndex] : null;
        if (nextStep) {
          addNodes(nodes, [nextStep], level + 1, null, edges);
          edges.push({ id: `e${step.code}-${nextStep.code}`, source: step.code, target: nextStep.code });
        }
      }
    });
  };

  useEffect(() => {
    let x = 0;
    let y = 0;

    const newNodes = [];
    const newEdges = [];

    const welcomeNode = flow.steps && flow.steps.find(s => s.type == 'Welcome');
    if (welcomeNode) {
      addNodes(newNodes, [welcomeNode], 0, 0, newEdges);
    }

    var maxX = newNodes.reduce((max, node) => Math.max(max, node.position.x), 0);

    newNodes.filter(node => node.position.x == 0 || node.position.x == maxX).forEach(node => {
      const nodesOnRow = newNodes.filter(n => n.position.y == node.position.y);
      if (nodesOnRow.length === 1) {
        node.position.x = (maxX / 2);
      }
    });

    setNodes(newNodes);
    setEdges(newEdges);
  }, [flow, currentNode]);

  const getData = async () => {
    setLoading(true);

    if (id === "new") {
      const welcomeStep = { id: '00000000-0000-0000-0000-000000000000', type: 'Welcome', code: 'welcome', text: 'Olá, seja muito bem-vindo(a)' };

      setFlow({ active: true, steps: [welcomeStep] });
      setLoading(false);
    }
    else {
      try {
        var { data } = await getFlow(id);
        setFlow(data);
        setLoading(false);
      }
      catch (e) {
        exception(e);
      };
    }
  }

  const onSubmit = (data) => {

    if (data) {
      save(data);
    }
  }

  const save = async (data) => {
    try {
      await saveFlow(data);
      success(t('Saved.Flow'), 'success');
      navigate('../flow');
    }
    catch (e) {
      exception(e);
    };
  }

  const newStep = () => {
    setSelectedStep({ type: "Welcome" });
  }

  const saveStep = () => {

    const currentStepIndex = flow.steps.findIndex(s => s.code == selectedStep.oldCode);

    setFlow(currentFlow => {
      const newFlow = { ...currentFlow };

      if (currentStepIndex >= 0) {
        newFlow.steps[currentStepIndex] = { ...selectedStep };
      }
      else {
        newFlow.steps.push({ ...selectedStep });
      }

      return newFlow
    });

    setSelectedStep();
    success(t('Saved.Step'), 'Sucess');
  }

  const closeDeleteModal = (confirmDelete) => {

    setDeletingStep(null);

    if (confirmDelete) {
      const currentStepIndex = flow.steps.findIndex(s => s.code === selectedStep.oldCode);
      flow.steps.splice(currentStepIndex, 1);

      setSelectedStep();
      success(t('Step.Excluded'), 'Sucess');
    }
  }

  const onCancel = () => {
    navigate(-1);
  }

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  const onNodeMouseEnter = useCallback(
    (event, node) => setCurrentNode(node.id),
    [setCurrentNode]
  );

  const onNodeMouseLeave = useCallback(
    (event, node) => setCurrentNode(null),
    [setCurrentNode]
  );

  const formFields = [
    {
      dataField: 'name',
      text: t('Name'),
      xs: 6, sm: 4, md: 6,
      required: true,
    }
  ];

  const formButtons = [
    {
      color: "primary",
      type: "submit",
      text: t("Save")
    },
    {
      type: "reset",
      text: t("Cancel"),
      variant: "outlined"
    }
  ];

  const flowView =
    (
      <Container sx={{ width: '100%', height: '70vh' }}>
        <ReactFlow
          nodesDraggable={false}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodeMouseEnter={onNodeMouseEnter}
          onNodeMouseLeave={onNodeMouseLeave}
          fitView
        />
      </Container>
    );

  const form = (
    <SmartForm
      data={flow}
      fields={formFields}
      buttons={formButtons}
      onSubmit={(data) => onSubmit(data)}
      onCancel={onCancel}
      footer={flowView}
    >
    </SmartForm>
  );

  const StepTypes = {
    Welcome: {
      value: "Welcome", label: t("Welcome")
    },
    Text: {
      value: "Text", label: t("Text")
    },
    MultipleChoice: {
      value: "MultipleChoice", label: t("MultipleChoice")
    },
    Statement: {
      value: "Statement", label: t("Statement")
    },
    QnA: {
      value: "QnA", label: t("QnA")
    },
    Name: {
      value: "Name", label: t("Name")
    },
    NationalId: {
      value: "NationalId", label: t("NationalId")
    },
    Ending: {
      value: "Ending", label: t("Ending")
    },
  }

  // const StepRules = {
  //   Action: { value: 1, label: t('Action') },
  //   Conditions: { value: 2, label: t('Conditions') },
  // }

  // const DefaultActions = {
  //   GoTo: { value: "GoTo", label: t('GoTo') },
  //   StayOk: { value: "StayOk", label: t('StayOk') },
  //   StayError: { value: "StayError", label: t('StayError') },
  //   Return: { value: "Return", label: t('Return') },
  // }

  const commonFields = [
    {
      dataField: 'code',
      text: t('Step.Code'),
      xs: 6, sm: 4, md: 6,
      type: 'textarea',
      required: true,
    },
    {
      dataField: 'type',
      text: t('Step.Type'),
      xs: 6, sm: 4, md: 6,
      type: 'select',
      validValues: Object.values(StepTypes).filter(t => t.value).map(m => ({ value: m.value, label: m.label })),
      required: true,
    },
    {
      dataField: 'text',
      text: t('Text'),
      xs: 6, sm: 4, md: 12,
      type: 'textarea',
      required: true,
    },
    // {
    //   dataField: 'rules',
    //   text: t('Rules'),
    //   xs: 6, sm: 4, md: 6,
    //   type: 'select',
    //   validValues: Object.values(StepRules).map(m => ({ value: m.value, label: m.label })),
    //   required: true,
    // },
    // {
    //   dataField: 'defaultAction',
    //   text: t('Step.DefaultAction'),
    //   xs: 6, sm: 4, md: 6,
    //   type: 'select',
    //   validValues: Object.values(DefaultActions).map(m => ({ value: m.value, label: m.label })),
    //   required: true,
    // },
    {
      dataField: 'errorMessage',
      text: t('Error.Message'),
      xs: 6, sm: 4, md: 6,
      required: true,
    },
  ];

  const extraFields = {
    Welcome: [],

    Text: [
      {
        dataField: 'minLength',
        text: t('MinLength'),
        xs: 6, sm: 4, md: 6,
      },
      {
        dataField: 'maxLength',
        text: t('MaxLength'),
        xs: 6, sm: 4, md: 6,
      },
      {
        dataField: 'mask',
        text: t('Mask'),
        xs: 6, sm: 4, md: 6,
      },
      {
        dataField: 'multiline',
        text: t('Multiline'),
        xs: 6, sm: 4, md: 6,
        type: 'boolean',
      },
    ],

    Statement: [],

    QnA: [
      {
        dataField: 'minLength',
        text: t('MinLength'),
        xs: 6, sm: 4, md: 6,
      },
      {
        dataField: 'maxLength',
        text: t('MaxLength'),
        xs: 6, sm: 4, md: 6,
      },
    ],

    NationalId: [],

    Name: [
      {
        dataField: 'fullName',
        text: t('FullName'),
        xs: 6, sm: 4, md: 6,
        type: 'boolean',
      },
      {
        dataField: 'blackList',
        text: t('BlackList'),
        xs: 6, sm: 4, md: 6,
        required: true,
      },
    ],

    MultipleChoice: [
      {
        dataField: 'allowMultiple',
        text: t('AllowMultiple'),
        xs: 6, sm: 4, md: 6,
        type: 'boolean',
        required: true
      },
      // {
      //   dataField: 'validValues',
      //   text: t('ValidValues'),
      //   xs: 6, sm: 4, md: 6,
      //   required: true,
      // }
    ],

    Ending: [],
  }

  const persistData = {
    dataField: 'persistData',
    text: t('Step.PersistData'),
    xs: 6, sm: 4, md: 6,
    type: 'boolean',
  };

  const formStepFields = selectedStep?.type && commonFields.concat(extraFields[selectedStep.type].concat(persistData));

  const formStep = (
    <SmartForm sx={{ mt: { md: 2 } }}
      data={selectedStep}
      fields={formStepFields}
      onSubmit={(data) => onSubmit(data)}
      onCancel={onCancel}
      onChange={(data) => setSelectedStep(data)}
    >
    </SmartForm>
  );

  const flowStepDialog = (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={selectedStep ? true : false}
    >
      <DialogTitle>{t('Step.Edit')}</DialogTitle>
      <DialogContent>
        {formStep}
      </DialogContent>
      <DialogActions>
        <Button key={"btnClose"} onClick={() => setSelectedStep()}>{t('Close')}</Button>
        <Button key={"btnDelete"} color="error" onClick={() => setDeletingStep(true)}>{t('Delete')}</Button>
        <Button key={"btnSave"} variant="contained" onClick={saveStep}>{t('Save')}</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    loading
      ? <div className="loading"> <LinearProgress color="primary" /></div>
      :
      <Page title="Flow">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Flow
            </Typography>
          </Stack>
          <Button onClick={newStep}>Novo</Button>

          <Card>
            <Container sx={{ my: { xs: 0 }, p: { xs: 2, md: 3 } }}>
              {form}
            </Container>
          </Card>

          {flowStepDialog}

          <Dialog
            open={deletingStep ? true : false}
            onClose={() => closeDeleteModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{t("AlertDialog.Title")}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("AlertDialog.Delete.Step")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button key={"BtnCancelStepDelete"} onClick={() => closeDeleteModal(false)} color="primary">
                {t("Cancel")}
              </Button>
              <Button key={"BtnConfirmStepDelete"} onClick={() => closeDeleteModal(true)} color="secondary" autoFocus>
                {t("Delete")}
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Page>
  );
}

export default FlowEdit;
