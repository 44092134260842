import useHttpClient from "../common/http-client";

const useFlowService = () => {

  const httpClient = useHttpClient();

  const getFlows = (id) => {
    return httpClient.get(`/flow`);
  };

  const getFlow = (id) => {
    return httpClient.get(`/flow/${id}`);
  };

  const saveFlow = (data) => {
    return httpClient.post(`/flow`, data);
  };

  const deleteFlow = (id) => {
    return httpClient.delete(`/flow/${id}`);
  };

  const cloneFlow = (id) => {
    return httpClient.post(`/flow/${id}/clone`);
  };

  return { getFlows, getFlow, saveFlow, deleteFlow, cloneFlow }
}

export default useFlowService;