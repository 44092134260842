import React, { useState, useEffect } from "react";
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Container } from "@mui/material";

const SmartButtons = ({ data, sx }) => {

  const getButtonFromData = (button, index, sx) => {
    if (button.visible === false) {
      return null;
    }

    const result = button.type === "icon"
      ? (<IconButton
        key={index}
        sx={sx}
        disabled={button.disabled ?? null}
        variant={button.variant || "outlined"}
        color={button.color}
        className={button.className}
        // type={button.type ?? "button"}
        onClick={button.onClick}
        size={button.size ?? "large"}>{button.icon}{button.text}</IconButton>
      )
      : (
        <Button
          key={index}
          sx={sx}
          disabled={button.disabled ?? null}
          variant={button.variant || "outlined"}
          color={button.color}
          fullWidth={button.fullWidth === true}
          className={button.className}
          type={button.type ?? "button"}
          startIcon={button.startIcon}
          onClick={button.onClick}>{button.icon}{button.text}
        </Button>
      );

    if (!button.tooltipText) {
      return result;
    }
    else {
      return (
        <Tooltip key={index} title={button.tooltipText}>
          {result}
        </Tooltip>
      );
    }
  }

  const buttons = [];

  if (data) {

    for (const [index, buttonData] of data.entries()) {

      if (buttonData.type === "button-group") {
        var buttonGroup = (
          <ButtonGroup key={index} color="primary" style={{ marginLeft: 1 }}>
            {buttonData.buttons.map((data, groupIndex) => getButtonFromData(data, groupIndex))}
          </ButtonGroup>
        );

        buttons.push(buttonGroup);
      }
      else {
        var style = index > 0 ? { marginLeft: 1 } : null;
        const item = getButtonFromData(buttonData, index, style);

        buttons.push(item);
      }
    }
  };

  return (
    <Container sx={{ width: "100%", display: "flex", justifyContent: 'end', paddingLeft: { xs: 0 }, paddingRight: { xs: 0 }, ...sx }}>
      {buttons}
    </Container>
  )
}

export default SmartButtons;
