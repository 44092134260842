import useHttpClient from "../common/http-client";

const useAccountService = () => {
  const httpClient = useHttpClient();

  const getAccounts = () => {
    return httpClient.get(`/account`);
  };

  const saveAccount = (data) => {
    return httpClient.post(`/account`, data);
  };

  const getAccount = (id) => {
    return httpClient.get(`/account/${id}`);
  };

  const deleteAccount = (id) => {
    return httpClient.delete(`/account/${id}`);
  };

  const forgotPassword = (email) => {
    return httpClient.post(`/account/forgot-password`, email);
  };

  const resetPassword = (email, token, password) => {
    var data = { email, token, password };
    return httpClient.post(`/account/reset-password`, data);
  };

  return { getAccounts, getAccount, saveAccount, deleteAccount, forgotPassword, resetPassword };
};

export default useAccountService;
