import useHttpClient from "../common/http-client";

const useReportService = () => {

  const httpClient = useHttpClient();

  const getReports = (id) => {
    return httpClient.get(`/report`);
  };

  const getReport = (id) => {
    return httpClient.get(`/report/${id}`);
  };

  const saveReport = (data) => {
    return httpClient.post(`/report`, data);
  };

  const deleteReport = (id) => {
    return httpClient.delete(`/report/${id}`);
  };

  const getFields = (flowId) => {
    return httpClient.get(`/report/fields`, { params: { flowId } });
  };

  const getTableData = (id, startDate, endDate) => {
    return httpClient.get(`/report/${id}/data`, { params: { startDate: startDate?.toJSON(), endDate: endDate?.toJSON() } });
  };

  return { getReports, getReport, saveReport, deleteReport, getFields, getTableData }
}

export default useReportService;