import React from 'react';
import { useState } from 'react';
import { visuallyHidden } from '@mui/utils';
import { Box, Checkbox, TableContainer, Table, TableRow, TableBody, TableCell, TableHead, TableSortLabel, TableFooter } from '@mui/material';


const SmartTable = ({ fields, rows, id, canSelect, onSelect, selectedIds }) => {

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState(selectedIds || []);
    const [orderBy, setOrderBy] = useState();
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState();

    const getRowId = (row) => (id && fields.length ? row[id] : row[fields[0]?.id]);

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((row) => getRowId(row));
            setSelected(newSelecteds);

            if (onSelect) {
                onSelect(newSelecteds);
            }
        }
        else {
            setSelected([]);

            if (onSelect) {
                onSelect([]);
            }
        }
    }

    const handleSelect = (row, event) => {
        const id = getRowId(row);
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);

        if (onSelect) {
            onSelect(newSelected);
        }
    };

    const header = (
        <TableHead>
            <TableRow>
                {canSelect && <TableCell padding="checkbox" key={"checkbox"}>
                    <Checkbox
                        indeterminate={selected.length > 0 && selected.length < rows?.length}
                        checked={rows?.length > 0 && selected.length === rows.length}
                        onChange={handleSelectAll}
                    />
                </TableCell>}
                {fields && fields.map((field, index) => {
                    let fieldHeader = field.header;

                    if (field.header && typeof field.header === "function") {
                        fieldHeader = field.header(field);
                    }

                    return (
                        <TableCell
                            key={index}
                            align={field.align ?? 'left'}
                            padding={field.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy && orderBy === field.id ? order : false}
                            sx={field.sx}
                        >
                            <TableSortLabel
                                hideSortIcon
                                active={orderBy && orderBy === field.id}
                                direction={orderBy && orderBy === field.id ? order : 'asc'}
                            >
                                {fieldHeader}
                                {orderBy && orderBy === field.id ? (
                                    <Box sx={{ ...visuallyHidden }}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );

    const bodyRows = rowsPerPage && rows?.length ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows;

    const body = (
        bodyRows?.length > 0 && <TableBody>
            {bodyRows
                .map((row, index) => {
                    const key = getRowId(row);
                    const isItemSelected = selected.indexOf(key) !== -1;

                    return (
                        <TableRow
                            hover
                            key={index}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                        >
                            {canSelect &&
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={isItemSelected}
                                        onChange={(event) => handleSelect(row, event)}
                                    />
                                </TableCell>
                            }
                            {fields && fields.map((field, fieldIndex) => {
                                let fieldValue;
                                if (field.id) {
                                    fieldValue = row[field.id];
                                }

                                if (field.data && typeof field.data === "function") {
                                    fieldValue = field.data(row, fieldValue);
                                }

                                return (
                                    <TableCell
                                        key={fieldIndex}
                                        align={field.align ?? 'left'}
                                        padding={field.disablePadding ? 'none' : 'normal'}
                                        sortDirection={orderBy === field.id ? order : false}
                                        sx={field.sx}
                                    >
                                        {fieldValue}
                                    </TableCell>
                                )
                            }
                            )}
                        </TableRow>
                    );
                })}
        </TableBody>
    );

    const hasFooter = fields && fields.find(f => f.footer);

    const footer = (
        hasFooter &&
        <TableFooter>
            <TableRow>
                {canSelect && <TableCell padding="checkbox" key={"checkbox"}>
                    <Checkbox
                        indeterminate={selected.length > 0 && selected.length < rows?.length}
                        checked={rows?.length > 0 && selected.length === rows.length}
                        onChange={handleSelectAll}
                    />
                </TableCell>}
                {fields && fields.map((field, fieldIndex) => {
                    let fieldValue = field.footer;

                    if (field.footer && typeof field.footer === "function") {
                        fieldValue = field.footer(field);
                    }

                    return (
                        <TableCell
                            key={fieldIndex}
                            align={field.align ?? 'left'}
                            padding={field.disablePadding ? 'none' : 'normal'}
                            sx={field.sx}
                        >
                            {fieldValue}
                        </TableCell>
                    )
                }
                )}
            </TableRow>
        </TableFooter>
    );


    return (
        <TableContainer sx={{}}>
            <Table>
                {header}
                {body}
                {footer}
            </Table>
        </TableContainer>
    )
}

export default SmartTable
