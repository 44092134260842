import { styled } from "@mui/material/styles";
import { Box, Button, Typography, Container, Stack } from "@mui/material";
import Page from "../../components/Page";
import useAccountService from "../../services/useAccountService";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Iconify from "../../components/Iconify";
import SmartTable from "../../components/smart-table/smart-table";
import Tooltip from "@mui/material/Tooltip";
import useNotify from "../../common/useNotify";
import { useTranslation } from "react-i18next";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [deleteModalId, setDeleteModalId] = useState();
  const { exception } = useNotify();
  const { getAccounts, deleteAccount } = useAccountService();
  const { t } = useTranslation();

  useEffect(() => {
    getData();
  }, []);

  const refreshList = () => {
    getData();
  };

  const getData = async () => {
    setLoading(true);
    try {
      var { data } = await getAccounts();
      setData(data);
      setLoading(false);
    } catch (e) {
      exception(e);
    }
  };

  const closeDeleteModal = async (confirmDelete) => {
    setDeleteModalId(null);

    if (confirmDelete) {
      try {
        await deleteAccount(deleteModalId);

        refreshList();
      } catch (e) {
        exception(e);
      }
    }
  };

  const deleteButton = (row) => {
    return (
      <Tooltip title="Delete">
        <IconButton onClick={() => setDeleteModalId(row.id)}>
          <Iconify icon="eva:close-circle-fill" width={20} height={20} />
        </IconButton>
      </Tooltip>
    );
  };

  const editButton = (row) => {
    return (
      <Tooltip title="Edit">
        <IconButton component={Link} to={`${row.id}`}>
          <Iconify icon="eva:edit-fill" width={20} height={20} />
        </IconButton>
      </Tooltip>
    );
  };
  const viewButton = (row) => {
    return (
      <Tooltip title="Edit">
        <IconButton component={Link} to={`${row.id}/view`}>
          <Iconify icon="eva:eye-fill" width={20} height={20} />
        </IconButton>
      </Tooltip>
    );
  };

  const tableFields = [
    { id: "name", header: t("E-mail") },
    // { data: editButton },
    // { data: viewButton },
    // { data: deleteButton },
  ];

  return loading ? (
    <div className="loading">
      {" "}
      <LinearProgress color="primary" />
    </div>
  ) : (
    <Page title="Accounts">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {t("Users")}
          </Typography>
          <Button
            variant="contained"
            component={Link}
            to="new"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            {t("New")}
          </Button>
        </Stack>

        <Card>
          <SmartTable fields={tableFields} rows={data} />
        </Card>

        <Dialog
          open={deleteModalId ? true : false}
          onClose={() => closeDeleteModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t(
                "Do you really want to delete this record? This process cannot be undone."
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closeDeleteModal(false)} color="primary">
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => closeDeleteModal(true)}
              color="secondary"
              autoFocus
            >
              {t("Delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
};

export default Home;
