import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import SmartForm from "../components/smart-form/smart-form.component";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Tooltip, IconButton } from "@mui/material";
import Iconify from "../components/Iconify";
import Typography from "@mui/material/Typography";
import useAccountService from "../services/useAccountService";
import useAuth from "../common/useAuth";
import { Link as RouterLink, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import useNotify from "../common/useNotify";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const [data] = useState();
  const { t } = useTranslation();
  const { resetPassword } = useAccountService();
  const { setAuth } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { error, success } = useNotify();

  useEffect(() => {
    setAuth({});
  }, [setAuth]);

  const onSubmit = async (formData) => {
    if (formData) {
      try {
        var token = searchParams.get("token");
        var userid = searchParams.get("userid");
        await resetPassword(userid, token, formData.password);
        success("Sua senha foi redefinida com sucesso.");
        navigate("../login");
      } catch (e) {
        error(e?.response?.statusText);
      }
    }
  };

  const formFields = [
    {
      dataField: "password",
      md: 12,
      sm: 12,
      xs: 12,
      margin: "normal",
      required: true,
      type: "password",
      id: "password",
      label: t("Password"),
      name: "password",
      autoComplete: "password",
      autoFocus: true,
    },
  ];
  const formButtons = [
    {
      fullWidth: true,
      type: "submit",
      variant: "contained",
      text: t("Send"),
    },
  ];
  const form = (
    <SmartForm
      sx={{ width: "100%" }}
      data={{}}
      fields={formFields}
      buttons={formButtons}
      onSubmit={(data) => onSubmit(data)}
    ></SmartForm>
  );

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(login.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 20,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <Iconify icon="ant-design:lock-outlined" />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("EnterPassword")}
          </Typography>
          <Box sx={{ width: "100%", paddingTop: "10px" }}>
            {form}
            <Grid container>
              <Grid item xs>
                {/* <Link href="#" variant="body2">
                                    {t("ForgotPassword")}
                                </Link> */}
                {/* <Tooltip title={t("Português (Brazil)")} >
                                    <IconButton onClick={() => setLoginInfo({ ...loginInfo, language: 'pt-BR' })} >
                                        <Iconify icon="emojione-v1:flag-for-brazil" width={20} height={20} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={t("English (United States)")} >
                                    <IconButton onClick={() => setLoginInfo({ ...loginInfo, language: 'en-US' })} >
                                        <Iconify icon="emojione-v1:flag-for-united-states" width={20} height={20} />
                                    </IconButton>
                                </Tooltip> */}
              </Grid>
              {/* <Grid item>
                                <Link href="#" variant="body2">
                                    {t("SignUp")}
                                </Link>
                            </Grid> */}
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
