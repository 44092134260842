import { Navigate, useRoutes } from 'react-router-dom';
import RequireAuth from './components/RequireAuth';

// layouts
import Layout from './layouts/default';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
// import Login from './pages/Login';
// import Register from './pages/Register';
import { FlowList, FlowEdit } from './pages/flow/flow';
import { ReportList, ReportEdit, ReportView } from './pages/report/report';
// import Products from './pages/Products';
// import Blog from './pages/Blog';
// import User from './pages/User';
import NotFound from './pages/NotFound';
import Login from './pages/Login';
import Home from './pages/Home';
import { UserList, UserEdit } from './pages/user/user';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <RequireAuth />,
      children: [
        {
          path: '/',
          element: <Layout />,
          children: [
            { path: '', element: <Navigate to="/home" /> },
            { path: 'home', element: <Home /> },

            { path: 'report', element: <ReportList /> },
            { path: 'report/:id', element: <ReportEdit /> },
            { path: 'report/:id/view', element: <ReportView /> },

            { path: 'flow', element: <FlowList /> },
            { path: 'flow/:id', element: <FlowEdit /> },

            { path: 'user', element: <UserList /> },
            { path: 'user/:id', element: <UserEdit /> },
          ],
        },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'logoff', element: <Login /> },
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: 'unauthorized', element: <Login /> },
        { path: '*', element: <Navigate to="/404" /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        {
          path: 'account/reset-password',
          element: <ResetPassword />,
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
