import React, { useState, useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Tooltip, IconButton } from '@mui/material';
import Iconify from '../components/Iconify';
import Typography from '@mui/material/Typography';
import useLoginService from '../services/useLoginService';
import useAuth from '../common/useAuth';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import useNotify from '../common/useNotify';
import { useTranslation } from "react-i18next";

const Login = () => {

  const { t } = useTranslation();
  const { loginPassword } = useLoginService();
  const { setAuth } = useAuth();
  const [loginInfo, setLoginInfo] = useState({
    email: localStorage.getItem("email") || "",
    remember: localStorage.getItem("remember") === "true" || false,
    language: localStorage.getItem("language") || 'pt-BR',
  });

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const { exception } = useNotify();

  useEffect(() => {
    setAuth({});
  }, [setAuth]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const password = data.get('password');

    try {
      const response = await loginPassword(loginInfo.email, password);

      const user = response?.data?.user;
      const token = response?.data?.token;
      const claims = response?.data?.claims;
      setAuth({ user, claims, token });
      localStorage.setItem("remember", loginInfo.remember);
      localStorage.setItem("language", loginInfo.language);

      if (loginInfo.remember) {
        localStorage.setItem("accessToken", token.accessToken);
        localStorage.setItem("email", loginInfo.email);
      } else {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("email");
      }

      navigate(from, { replace: true });
    } catch (err) {
      exception(err);
    }
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url(login.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <Iconify icon="ant-design:lock-outlined" />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("SignIn")}
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("Email")}
              name="email"
              autoComplete="email"
              autoFocus
              value={loginInfo.email}
              onChange={(e) => setLoginInfo({ ...loginInfo, email: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("Password")}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" checked={loginInfo.remember} onChange={(e) => setLoginInfo({ ...loginInfo, remember: e.target.checked })} />}
              name={'remember'}
              label={t("RememberMe")}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("SignIn")}
            </Button>
            <Grid item>
              <Link href='/forgot-password' variant='body2'>
                {t('ForgotPassword')}
              </Link>
            </Grid>
            <Grid container>
              <Grid item xs>
                {/* <Link href="#" variant="body2">
                                    {t("ForgotPassword")}
                                </Link> */}
                {/* <Tooltip title={t("Português (Brazil)")} >
                                    <IconButton onClick={() => setLoginInfo({ ...loginInfo, language: 'pt-BR' })} >
                                        <Iconify icon="emojione-v1:flag-for-brazil" width={20} height={20} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={t("English (United States)")} >
                                    <IconButton onClick={() => setLoginInfo({ ...loginInfo, language: 'en-US' })} >
                                        <Iconify icon="emojione-v1:flag-for-united-states" width={20} height={20} />
                                    </IconButton>
                                </Tooltip> */}
              </Grid>
              {/* <Grid item>
                                <Link href="#" variant="body2">
                                    {t("SignUp")}
                                </Link>
                            </Grid> */}
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Login;
