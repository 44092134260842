import useHttpClient from "../common/http-client";

const useLoginService = () => {
  const httpClient = useHttpClient(false);

  const loginPassword = (username, password) => {
    var data = { username, password };
    return httpClient.post(`/login/password`, data);
  };

  const getAuth = (accessToken) => {
    var data = { accessToken };
    return httpClient.post(`/login/token`, data);
  };

  return { loginPassword, getAuth };
};

export default useLoginService;
