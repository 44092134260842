export default {
  translations: {
    //Traduções gerais
    Logoff: 'Sair',
    Name: 'Nome',
    FullName: 'Nome completo',
    Type: 'Tipo',
    Created: {
      Default: 'Criado',
      At: 'Criado em',
    },
    Updated: {
      Default: 'Atualizado',
      At: 'Atualizado em',
    },
    Edit: 'Editar',

    Clone: 'Copiar',
    View: 'Visualizar',
    Save: 'Salvar',
    Send: 'Enviar',
    Cancel: 'Cancelar',
    Delete: 'Apagar',
    New: 'Novo',
    logoff: 'Sair',
    Email: 'Email',
    AlertDialog: {
      Title: 'Você tem certeza?',
      Delete: {
        Flow: 'Realmente deseja apagar esse flow? Esse processo não pode ser desfeito.',
        Step: 'Realmente deseja apagar esse passo? Esse processo não pode ser desfeito.',
        Report: 'Realmente deseja apagar esse Relatório? Esse processo não pode ser desfeito.',
      }
    },
    Add: {
      Default: 'Adicionar',
      Filter: 'Adicionar filtro'
    },
    Error: {
      Message: 'Mensagem de erro'
    },
    CountryCode: 'CPF',
    Close: 'Fechar',

    //Login
    SignIn: 'Entrar',
    Password: 'Senha',
    RememberMe: 'Lembrar meu usuário',
    ForgotPassword: 'Esqueceu sua senha?',
    EnterEmail: "Digite seu email para recuperar sua senha.",
    EnterPassword: "Digite a sua nova senha abaixo.",
    SignUp: "Ainda não tem uma conta? Increva-se",

    //Flow
    flow: 'Flow',
    Saved: {
      Flow: 'Flow salvo!',
      Report: 'Relatório salvo!'
    },
    Step: {
      Type: 'Tipo',
      Code: 'Código',
      DefaultAction: 'Ação padrão',
      PersistData: 'Salvar resposta?',
      Edit: 'Editar passo',
      Excluded: 'Passo excluído'
    },
    Welcome: 'Boas vindas',
    Text: 'Texto',
    MultipleChoice: 'Múltipla escolha',
    Statement: 'Afirmação',
    QnA: 'Perguntas e respostas',
    NationalId: 'CPF',
    Ending: 'Encerramento',
    MinLength: 'Tamanho mínimo',
    MaxLength: 'Tamanho máximo',
    Mask: 'Máscara',
    Multiline: 'Mais de uma linha?',
    BlackList: 'Lista proibida',
    AllowMultiple: 'Permitir mais de um?',
    ValidValues: 'Valores válidos',
    Rules: 'Regra',
    Action: 'Ação',
    Conditions: 'Condições',
    GoTo: 'Ir para',
    StayOk: 'Continuar nesse passo após êxito',
    StayError: 'Continuar nesse passo após erro',
    Return: 'Voltar',

    //Reports
    Reports: 'Relatórios',
    Report: 'Relatório',
    ReportsQuantity_zero: "Nenhum registro encontrado",
    ReportsQuantity_one: "{{count}} registro encontrado",
    ReportsQuantity_other: "{{count}} registros encontrados",
    Date: {
      Start: 'Data de início',
      End: 'Data final'
    },
    AutoRefresh: 'Atualizar automaticamente',
    Fields: 'Campos',
    Field: 'Campo',
    Filters: 'Filtros',
    Value: 'Valor',
    Equals: '='
  }
}
