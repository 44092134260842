// component
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export const sidebarCommon = [
  {
    title: "logoff",
    path: "/logoff",
    icon: getIcon("ant-design:logout-outlined"),
  },
];

export const sidebarAdmin = [
  {
    title: "flow",
    path: "/flow",
    icon: getIcon("eva:people-fill"),
  },
  {
    title: "users",
    path: "/user",
    icon: getIcon("eva:people-outline"),
  },
];

export const sidebarReport = [
  {
    title: "Reports",
    path: "/report",
    icon: getIcon("eva:book-fill"),
  },
];
