import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import useReportService from "../../services/useReportService";
import Page from '../../components/Page';
import { Stack } from '@mui/material'
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import SmartTable from '../../components/smart-table/smart-table';
import SmartForm from '../../components/smart-form/smart-form.component';
import useNotify from '../../common/useNotify';
import { useTranslation } from "react-i18next";
import { startOfDay, endOfDay } from "date-fns";

const ReportView = () => {

  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState();
  const [view, setView] = useState([]);
  const [params, setParams] = useState({ startDate: startOfDay(new Date()), endDate: endOfDay(new Date()), autoRefresh: false });
  const [timeoutId, setTimeoutId] = useState();
  const { exception } = useNotify();
  const { getReport, getTableData } = useReportService();
  const { id } = useParams();
  const { t } = useTranslation();


  useEffect(() => {
    getReportData();
  }, []);

  useEffect(() => {
    if (report && params) {
      getViewData();
    }
  }, [report, params]);

  const getReportData = async () => {
    setLoading(true);

    try {
      var reportData = await getReport(id);

      setReport(reportData.data);
    }
    catch (e) {
      exception(e);
    };
  }

  const getViewData = async () => {
    setLoading(true);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    try {
      console.log(new Date());
      var viewData = await getTableData(id, params.startDate, params.endDate);

      if (params.autoRefresh) {
        setTimeoutId(setTimeout(getViewData, 60000));
      }

      setView(viewData.data);
      setLoading(false);
    }
    catch (e) {
      exception(e);
    };
  }

  const tableFields = report?.fields?.map(f => ({ id: f, header: f }));

  const formFields = [
    {
      dataField: 'startDate',
      text: t('Date.Start'),
      type: 'date',
      xs: 6, sm: 4, md: 3,
    },
    {
      dataField: 'endDate',
      text: t('Date.End'),
      type: 'date',
      xs: 6, sm: 4, md: 3,
    },
    {
      dataField: 'autoRefresh',
      text: t('AutoRefresh'),
      type: 'boolean',
      xs: 6, sm: 4, md: 3,
    },
  ];

  const form = (
    <SmartForm
      data={params}
      fields={formFields}
      onChange={(data) => setParams(data)}
    >
    </SmartForm>
  );

  return (
    <Page title="Report">
      {loading && <div className="loading"> <LinearProgress color="primary" /></div>}
      {report && view &&
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {report.name}
            </Typography>
          </Stack>

          <Card>
            <Container sx={{ my: { xs: 0 }, p: { xs: 2, md: 3 } }}>
              {form}
              <label>{t('ReportsQuantity', { count: view.length })}</label>
            </Container>
            <Container sx={{ my: { xs: 0 }, p: { xs: 2, md: 3 } }}>
              <SmartTable
                fields={tableFields}
                rows={view}
              />
            </Container>
          </Card>
        </Container>
      }
    </Page>
  )
}

export default ReportView;
