import { useEffect } from "react";
import axios from "axios";
import useAuth from "./useAuth";
import { useNavigate } from 'react-router-dom';


const api = axios.create({
  baseURL: "api",
  headers: {
    "Content-type": "application/json"
  },
});

const useHttpClient = (setAccessToken = true) => {

  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {

    if (setAccessToken) {

      const requestIntercept = api.interceptors.request.use(
        config => {
          if (!config.headers.Authorization) {
            if (auth?.token?.accessToken) {
              config.headers.Authorization = `Bearer ${auth.token.accessToken}`;
            }
          }
          return config;
        },
        (error) => {
          return Promise.reject(error)
        }
      );

      const responseIntercept = api.interceptors.response.use(
        response => response,
        async (error) => {
          if (error?.response?.status === 401) {
            navigate('/login');
          }
          return Promise.reject(error);
        }
      );

      return () => {
        api.interceptors.request.eject(requestIntercept);
        api.interceptors.response.eject(responseIntercept);
      }
    }
  }, [auth]);

  return api;
}

export default useHttpClient;