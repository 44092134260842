import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import Collapse from '@mui/material/Collapse';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Iconify from '../../components/Iconify';

const SmartList = ({ value, items, pageSize, collapse, radio, toggle, check, header, avatar, allowDelete, allowReorder, allowCollapse, title, subtitle, toolbar, footerActions, dense, onSelect, onMove, onDelete, onClick }) => {

    let selected = value && items?.find(item => item.key === value.key);
    if (!selected && items?.length === 1) {
        selected = items[0];
    }

    const stateItems = items;

    const [state, setState] = useState({
        pageSize: pageSize ?? 10,
        currentPage: 1,
        collapsed: collapse || false,
        selected: selected
    });

    const [search, setSearch] = useState();
    const [currentPage, setCurrentPage] = useState();

    useEffect(() => {
        if (state.searchText) {
            const filteredData = getFilteredData();

            if (filteredData?.length < (state.pageSize * state.currentPage)) {
                setCurrentPage(1);
            }
        }
    }, [search]);


    const onSearchInputChange = (event) => {
        const searchText = event.target.value;
        setSearch(searchText);
    };

    const handleListClick = (item, index) => {
        if (onClick) {
            onClick(item, index);
        }
    }

    const handleDeleteClick = (item) => {

        const currentItemIndex = stateItems.findIndex(i => i.key === item.key);

        stateItems.splice(currentItemIndex, 1)

        setState(prevState => ({ ...prevState, items: stateItems }));

        if (onDelete) {
            onDelete(item, stateItems);
        }
    }

    const handleMoveUpClick = (item) => {

        const currentItemIndex = stateItems.findIndex(i => i.key === item.key);
        const previousItemIndex = currentItemIndex - 1;

        if (currentItemIndex >= 0 && previousItemIndex >= 0) {
            stateItems.splice(previousItemIndex, 0, stateItems.splice(currentItemIndex, 1)[0]);

            setState(prevState => ({ ...prevState, items: stateItems }));
        }

        const movedItem = stateItems.find(i => i.key === item.key);

        if (onMove) {
            onMove.call(this, movedItem, stateItems);
        }
    }

    const handleMoveDownClick = (item) => {

        const currentItemIndex = stateItems.findIndex(i => i.key === item.key);
        const nextItemIndex = currentItemIndex + 1;

        if (currentItemIndex >= 0 && nextItemIndex < stateItems.length) {
            stateItems.splice(nextItemIndex, 0, stateItems.splice(currentItemIndex, 1)[0]);

            setState(prevState => ({ ...prevState, items: stateItems }));
        }

        const movedItem = stateItems.find(i => i.key === item.key);

        if (onMove) {
            onMove.call(this, movedItem, stateItems);
        }
    }

    const handlePageClick = (event, page) => {
        setCurrentPage(page);
    }

    const selectSwitch = (item) => {

        var currentItem = stateItems.find(i => i.key === item.key);
        currentItem.selected = !item.selected;

        const selectedItems = stateItems.filter(i => i.selected);

        setState(prevState => ({ ...prevState, items: stateItems }));

        if (onSelect) {
            onSelect.call(this, item, selectedItems);
        }
    }

    const selectRadio = (key) => {

        setState(prevState => ({ ...prevState, selected: prevState.items.find(i => i.key === key) }));

        const selectedItems = stateItems.filter(i => i.selected);

        if (onSelect) {
            onSelect.call(this, state.selected, selectedItems);
        }
    }

    const selectAllItems = (selected) => {
        const filteredData = getFilteredData();

        filteredData.forEach(function (item) {
            var currentItem = stateItems.find(i => i.key === item.key);
            currentItem.selected = selected;
        });

        setState(prevState => ({ ...prevState, items: stateItems }));

        const selectedItems = stateItems.filter(i => i.selected);

        if (onSelect) {
            filteredData.forEach(function (item) {
                onSelect.call(this, item, selectedItems);
            });
        }
    }

    const toggleExpand = () => {
        setState(prevState => ({ ...prevState, collapsed: !prevState.collapsed }));
    }

    const getFilteredData = () => {
        const filteredData = stateItems && stateItems.filter(s => !state.searchText || s.text.toLowerCase().indexOf(state.searchText.toLowerCase()) >= 0);

        return filteredData;
    }


    const filteredData = getFilteredData();

    if (!filteredData) {
        return null;
    }

    const pageCount = Math.ceil(filteredData.length / state.pageSize);

    const pageStart = state.pageSize * (state.currentPage - 1);
    const pageEnd = pageStart + state.pageSize;

    const data = filteredData.slice(pageStart, pageEnd);

    const itemList = [];

    for (const [index, item] of data.entries()) {

        const btnSwitch = toggle && (
            <ListItemIcon sx={{ mr: 0 }}>
                <IconButton onClick={() => selectSwitch(item)} color={item.selected ? "primary" : "default"} >
                    {item.selected
                        ? <Iconify icon="eva:toggle-right-fill" />
                        : <Iconify icon="eva:toggle-left-outline" />
                    }
                </IconButton>
            </ListItemIcon>);

        const btnCheck = check && (
            <ListItemIcon sx={{ mr: 0 }}>
                <IconButton onClick={() => selectSwitch(item)} color={item.selected ? "primary" : "default"} >
                    {item.selected
                        ? <Iconify icon="eva:checkmark-square-2-fill" />
                        : <Iconify icon="eva:square-fill" />
                    }
                </IconButton>
            </ListItemIcon>);

        const btnMoveUp = allowReorder && (
            <ListItemIcon sx={{ mr: 0 }}>
                <IconButton onClick={() => handleMoveUpClick(item)} >
                    <Iconify icon="eva:arrow-circle-up-outline" />
                </IconButton>
            </ListItemIcon>);

        const btnMoveDown = allowReorder && (
            <ListItemIcon sx={{ mr: 0 }}>
                <IconButton onClick={() => handleMoveDownClick(item)} >
                    <Iconify icon="eva:arrow-circle-down-outline" />
                </IconButton>
            </ListItemIcon>);

        const btnDelete = allowDelete && (
            <ListItemIcon sx={{ mr: 0 }}>
                <IconButton onClick={() => handleDeleteClick(item)} >
                    <Iconify icon="eva:close-circle-outline" />
                </IconButton>
            </ListItemIcon>);

        const listItem = (
            <ListItem key={item.key} disableGutters button={item.button || null}>
                {item.icon && <ListItemIcon onClick={() => handleListClick(item, index)}>
                    {item.icon}
                </ListItemIcon>}
                {!radio && <ListItemText primary={item.text} onClick={() => handleListClick(item, index)} />}
                {radio &&
                    <FormControlLabel value={item.key} control={<Radio color="primary" sx={{ marginLeft: "auto", padding: "initial" }} />} label={item.text} labelPlacement="start" sx={{ width: "100%" }} />
                }
                <ListItemSecondaryAction sx={{ right: "0" }}>
                    {btnSwitch}
                    {btnCheck}
                    {btnMoveUp}
                    {btnMoveDown}
                    {btnDelete}
                </ListItemSecondaryAction>
            </ListItem>
        );

        itemList.push(listItem);
    };

    const allItemsSelected = filteredData.filter(f => !f.selected).length === 0;

    const listHeader = header ? (
        <CardHeader sx={{ paddingTop: "8px", paddingBottom: "8px", backgroundColor: "primary" }}
            avatar={avatar}
            action={allowCollapse &&
                <IconButton color="inherit" onClick={() => toggleExpand()} size="large">
                    <Iconify icon="eva:expand-fill" />
                </IconButton>
            }
            title={title}
            subheader={subtitle}
        />
    ) : null;

    const listToolbar = toolbar ? (
        <CardActions sx={{ marginRight: "8px", marginLeft: "8px" }}>
            {search && <Grid container alignItems="flex-end">
                <Grid item>
                    <Iconify icon="eva:search-fill" />
                </Grid>
                <Grid item>
                    <TextField id="input-with-icon-grid" label="Search" onChange={onSearchInputChange} variant="standard" />
                </Grid>
            </Grid>}
            {toggle &&
                <Switch
                    edge="end"
                    color="primary"
                    onChange={(event, checked) => selectAllItems(checked)}
                    checked={allItemsSelected}
                />
            }
        </CardActions>
    ) : null;

    const footer = footerActions ? footerActions.map((action, index) => {
        const item = (
            <Button key={index} size="small" color={action.color} variant={action.variant} onClick={action.onClick} startIcon={action.startIcon}>
                {action.text}
            </Button>
        );

        return item;
    }) : null;

    const listFooter = footer ? (
        <CardActions>
            {footer}
        </CardActions>
    ) : null;

    return (
        <Card variant="outlined" className="smart-list" sx={{ display: "flex", flexDirection: "column", alignSelf: state.collapsed || allowCollapse ? "flex-start" : "initial", alignItems: "stretch" }}>
            {listHeader}
            <Collapse in={!state.collapsed} timeout="auto" sx={{ flex: "1", flexDirection: "column", display: state.collapsed ? "none" : "initial" }}>
                {listToolbar}
                <CardContent>
                    <Grid container sx={{ flexDirection: "column", justifyContent: "space-between" }}>
                        <Grid item sx={{ maxWidth: "100%" }}>
                            <List dense={dense || null}>
                                {radio &&
                                    <RadioGroup value={state.selected?.key || null} onChange={(event) => selectRadio(event.target.value)}>
                                        {itemList}
                                    </RadioGroup>}
                                {!radio && itemList}
                            </List>
                        </Grid>
                        <Grid item>
                            {pageCount > 1 && <Pagination count={pageCount} onChange={handlePageClick} sx={{ display: "flex", justifyContent: "center" }} />}
                        </Grid>
                    </Grid>
                </CardContent>
                {listFooter}
            </Collapse>
        </Card>
    )
}
export default SmartList;