import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../components/Page";
import { Stack } from "@mui/material";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import useAccountService from "../../services/useAccountService";
import SmartForm from "../../components/smart-form/smart-form.component";
import useNotify from "../../common/useNotify";
import LinearProgress from "@mui/material/LinearProgress";
import { useTranslation } from "react-i18next";

const AccountEdit = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();
  const { success, exception } = useNotify();
  const { getAccount, saveAccount } = useAccountService();
  const { t } = useTranslation();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);

    if (id === "new") {
      setData({});
      setLoading(false);
    } else {
      try {
        var { data } = await getAccount(id);
        setData(data);
        setLoading(false);
      } catch (e) {
        exception(e);
      }
    }
  };

  const onSubmit = (formData) => {
    if (formData) {
      try {
        saveAccount(formData);
        success("User created!", "success");
        navigate("../user");
      }
      catch (e) {
        exception(e);
      }
    }
  };

  const save = async (data) => {
    try {
      await saveAccount(data);
      success("User created!", "success");
    } catch (e) {
      exception(e);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  const formFields = [
    {
      dataField: "username",
      text: t("Email"),
      id: "username",
      name: "username",
      autoComplete: "username",
      type: "email",
      xs: 6,
      sm: 4,
      md: 6,
      required: true,
    },
    {
      dataField: "admin",
      text: t("Admin"),
      id: "admin",
      name: "admin",
      type: "boolean",
      xs: 4,
      sm: 4,
      md: 4
    },
  ];

  const formButtons = [
    {
      color: "primary",
      type: "submit",
      text: t("Save"),
    },
    {
      type: "reset",
      text: t("Cancel"),
      variant: "outlined",
    },
  ];

  const form = (
    <SmartForm
      data={data}
      fields={formFields}
      buttons={formButtons}
      onSubmit={(data) => onSubmit(data)}
      onCancel={onCancel}
    ></SmartForm>
  );

  return loading ? (
    <div className="loading">
      {" "}
      <LinearProgress color="primary" />
    </div>
  ) : (
    <Page title="User">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {t("Users")}
          </Typography>
        </Stack>

        <Card>
          <Container sx={{ my: { xs: 0 }, p: { xs: 2, md: 3 } }}>
            {form}
          </Container>
        </Card>
      </Container>
    </Page>
  );
};

export default AccountEdit;
