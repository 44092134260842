import React, { useState, useEffect } from "react";

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { Chip, Stack, Switch, Typography, Select, ListSubheader, ListItemText, FormControl, InputLabel } from '@mui/material'
import Divider from '@mui/material/Divider';
import SmartButtons from '../smart-buttons/smart-buttons.component';
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { parse, format } from "date-fns";

const SmartForm = ({ data, onChange, buttons, onSubmit, onCancel, footer, fields, sx, disabled }) => {

  const [formData, setData] = useState(data ?? {});
  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = loadItems();
    setItems(items);
  }, [formData]);

  const handleInputChange = (event) => {

    const target = event.target;
    const name = target.name;

    const value = target.type === 'checkbox' ? target.checked : target.value;

    const newData = { ...formData, [name]: value };
    setData(newData);

    if (onChange) {
      onChange.call(null, newData);
    }
  }

  const handleSubmit = (event) => {

    event.preventDefault();

    if (onSubmit) {
      onSubmit.call(null, formData);
    }
  }

  const handleCancel = (event) => {

    event.preventDefault();

    if (onCancel) {
      onCancel.call();
    }
  }

  const fieldGroup = (fields, index) => {
    return (<Grid key={index} sx={{ width: '100%' }}
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}>
      {fields}
    </Grid>
    );
  }

  const isValidDate = (testDate) => {
    return testDate instanceof Date && !isNaN(testDate)
  };


  const loadItems = () => {
    let groupItems = []
    let groups = [];

    if (fields) {
      for (const [index, field] of fields.entries()) {

        if (field.visible === false) {
          continue;
        }

        let item;
        let fieldValue = formData?.hasOwnProperty(field.dataField) ? formData[field.dataField] : null;
        let fieldTitle = field.text;

        switch (field.type?.toLowerCase()) {
          case "separator":
            groups.push(fieldGroup(groupItems, groups.length));
            groupItems = [];

            item = (
              <Divider
                textAlign={field.textAlign}
                sx={{ ...{ my: 3, width: '100%' }, ...field.sx }}
                key={groups.length}
                style={{ fontSize: '1.5rem', fontWeight: 'bold' }}
              >
                {field.label}
              </Divider>
            );

            groups.push(item);
            continue;

          case "chip":
            groups.push(fieldGroup(groupItems, groups.length));
            groupItems = [];

            item = (
              <Divider textAlign={field.textAlign} sx={{ my: 3, width: '100%' }} key={groups.length}>
                <Chip label={field.label} style={{ fontSize: '1rem', fontWeight: 'bold' }} />
              </Divider>
            );

            groups.push(item);
            continue;

          case "select":
            const selectFieldValue = field.multiple ? fieldValue || [] : field.validValues.find(v => v.value === fieldValue)?.value || '';
            const selectGroups = [];
            const selectItems = [];
            field.validValues.forEach(selectItem => {
              let group = selectGroups.find(g => g.name === selectItem.group ?? 'default');
              if (group === undefined) {
                group = { name: selectItem.group, items: [] };
                selectGroups.push(group);
              }
              group.items.push(selectItem);
            });

            selectGroups.forEach((group, groupIndex) => {
              if (group.name) {
                selectItems.push(<ListSubheader key={groupIndex}>{group.name}</ListSubheader>);
              }
              group.items.forEach((selectItem, itemIndex) => {
                const key = `group${groupIndex}_item${itemIndex}`;
                selectItems.push(
                  field.multiple
                    ? (
                      <MenuItem key={key} value={selectItem.value}>
                        <Checkbox key={`checkbox`} checked={selectFieldValue?.indexOf(selectItem.value) > -1} />
                        <ListItemText key={`text`} primary={selectItem.label} />
                      </MenuItem>
                    )
                    : (
                      <MenuItem key={key} value={selectItem.value}>
                        {selectItem.label}
                      </MenuItem>
                    )
                );
              });
            });
            item = (
              <FormControl key={index} sx={{ width: '100%' }}>
                <InputLabel key={'label'} id={`label${field.dataField}`}>{fieldTitle}</InputLabel>
                <Select key={'select'}
                  id={field.dataField}
                  name={field.dataField}
                  labelid={`label${field.dataField}`}
                  label={fieldTitle}
                  value={selectFieldValue}
                  size={field.size}
                  className={field.className}
                  onChange={handleInputChange}
                  renderValue={(selected) => field.multiple
                    ? field.validValues.filter(v => selected.indexOf(v.value) > -1).map(v => v.label).join(', ')
                    : field.validValues.find(v => v.value === fieldValue)?.label}
                  required={field.required || null}
                  disabled={disabled || field.disabled || null}
                  multiple={field.multiple || null}
                >
                  {selectItems}
                </Select>
              </FormControl>
            );
            break;
          case "textarea":
            item = (
              <TextField
                key={index}
                type={field.type || 'text'}
                id={field.dataField}
                name={field.dataField}
                value={fieldValue || ''}
                // defaultValue={fieldValue}
                size={field.size}
                className={field.className}
                label={fieldTitle}
                onChange={handleInputChange}
                required={field.required || null}
                maxRows={field.maxRows}
                disabled={disabled || field.disabled || null}
                variant="outlined"
                multiline
                fullWidth
              />
            );
            break;
          case "boolean":
            item = (
              <FormControlLabel
                key={index}
                control={<Checkbox
                  checked={fieldValue || false}
                  color="primary"
                  onChange={handleInputChange}
                  id={field.dataField}
                  name={field.dataField}
                />}
                disabled={disabled || field.disabled || null}
                label={fieldTitle}
              />
            );
            fieldTitle = null;
            break;

          case "switch":
            item = (
              <FormControlLabel
                key={index}
                control={<Switch
                  checked={fieldValue || false}
                  color="secondary"
                  onChange={handleInputChange}
                  id={field.dataField}
                  name={field.dataField}
                />}
                disabled={disabled || field.disabled || null}
                label={fieldTitle}
              />
            );
            fieldTitle = null;
            break;



          case "date":
            item = (
              <LocalizationProvider dateAdapter={AdapterDateFns} key={index}>
                <DatePicker
                  label={fieldTitle}
                  value={fieldValue}
                  inputFormat="dd/MM/yyyy"
                  name={field.dataField}
                  variant="outlined"
                  className={field.className}
                  disabled={disabled || field.disabled || null}
                  onChange={(date) => handleInputChange({
                    target: { name: field.dataField, value: date }
                  })}
                  renderInput={(params) => {
                    return (<TextField
                      required={field.required || null}
                      {...params} />)
                  }}
                />
              </LocalizationProvider>
            );
            break;
          case "datetime":
            item = (
              <LocalizationProvider dateAdapter={AdapterDateFns} key={index}>
                <DateTimePicker
                  label={fieldTitle}
                  value={fieldValue}
                  inputFormat="dd/MM/yyyy HH:mm:ss"
                  name={field.dataField}
                  variant="outlined"
                  className={field.className}
                  disabled={disabled || field.disabled || null}
                  onChange={(date) => handleInputChange({
                    target: { name: field.dataField, value: date }
                  })}
                  renderInput={(params) => {
                    return (<TextField
                      required={field.required || null}
                      {...params} />)
                  }}
                />
              </LocalizationProvider>
            );
            break;
          case "time":
            const fieldValueAsTime = parse(fieldValue, "HH:mm:ss", new Date());
            const validTime = fieldValueAsTime instanceof Date && !isNaN(fieldValueAsTime);

            item = (
              <LocalizationProvider dateAdapter={AdapterDateFns} key={index}>
                <TimePicker
                  label={fieldTitle}
                  value={validTime ? fieldValueAsTime : fieldValue}
                  ampm={false}
                  name={field.dataField}
                  variant="outlined"
                  className={field.className}
                  disabled={disabled || field.disabled || null}
                  onChange={(date) => {
                    handleInputChange({
                      target: { name: field.dataField, value: isValidDate(date) ? format(date, 'HH:mm:ss') : date }
                    })
                  }}
                  renderInput={(params) => {
                    return (<TextField
                      required={field.required || null}
                      {...params} />)
                  }}
                />
              </LocalizationProvider>
            );
            break;

          case "text":
            item = (
              <Typography
                variant={field.hx}
                children={field.text}
                gutterBottom
              />
            );
            fieldTitle = null;
            break;


          default:
            item = (
              <TextField
                key={index}
                variant="outlined"
                type={field.type || 'text'}
                id={field.dataField}
                name={field.dataField}
                // value={fieldValue || ''}
                defaultValue={fieldValue || ''}
                size={field.size}
                className={field.className}
                onChange={handleInputChange}
                disabled={disabled || field.disabled || null}
                required={field.required || null}
                label={fieldTitle}
                fullWidth
              />

            );
            break;
        }

        const formItem = (
          <Grid item xs={field.xs} sm={field.sm} md={field.md} key={item.key}>
            {item}
          </Grid>
        );

        groupItems.push(formItem)
      }
    }

    groups.push(fieldGroup(groupItems, groups.length));

    return groups;
  };

  const btn = (<SmartButtons data={buttons} />);

  return (
    <form onSubmit={handleSubmit} autoComplete="off" onReset={handleCancel} className="form-flex">
      <Stack direction="column" alignItems="start" justifyContent="space-between" sx={sx}>
        {items}
        <Grid sx={{ width: '100%' }}
          container
          spacing={2}
          mt={2}
          pl={2}
        >
          {footer}
          {btn}
        </Grid>
      </Stack>
    </form>
  )
}

export default SmartForm;
