export default {
  translations: { // Mesmo valor usado nas configurações (defaultNS)
    home: {
      message: 'Olá Mundo!'
    },
    SignIn: 'Sign In',
    SignUp: "Don't have an account? Sign Up",
    RememberMe: 'Remember me',
    ForgotPassword: 'Forgot password?',
    ReportsQuantity_zero: "None record found",
    ReportsQuantity_one: "{{count}} record found",
    ReportsQuantity_other: "{{count}} records found",
    EnterEmail: "Enter your email to recover your password",
    EnterPassword: "Enter your new password.",

    Saved: {
      Flow: 'Flow saved!',
      Report: 'Report saved!'
    },

    AlertDialog: {
      Title: 'Are you sure?',
      Delete: {
        Record: 'Do you really want to delete this record? This process cannot be undone.'
      }
    },
    CreatedAt: 'Created At',
    UpdatedAt: 'Updated At',
    Date: {
      Start: 'Start Date',
      End: 'End Date'
    },
    AutoRefresh: 'Auto Refresh',
    Add: {
      Default: 'Add',
      Filter: 'Add Filter'
    },
    Created: {
      Default: 'Created',
      At: 'Created At',
    },
    Updated: {
      Default: 'Updated',
      At: 'Updated At',
    },
    AlertDialog: {
      Title: 'Are you sure?',
      Delete: {
        Record: 'Do you really want to delete this record? This process cannot be undone.'
      }
    },
    CreatedAt: 'Created At',
    UpdatedAt: 'Updated At',
    Date: {
      Start: 'Start Date',
      End: 'End Date'
    },
    AutoRefresh: 'Auto Refresh',
    Add: {
      Default: 'Add',
      Filter: 'Add Filter'
    },
    Created: {
      Default: 'Created',
      At: 'Created At',
    },
    Updated: {
      Default: 'Updated',
      At: 'Updated At',
    },
  },
}
