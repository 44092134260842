import { createContext, useEffect, useState } from "react";
import useLoginService from '../services/useLoginService';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const [loading, setLoading] = useState(true);
    const { getAuth } = useLoginService();

    useEffect(() => {
        const getAuthByToken = async () => {

            const accessToken = localStorage.getItem("accessToken");

            if (accessToken) {

                try {
                    const response = await getAuth(accessToken)

                    const user = response?.data?.user;
                    const token = response?.data?.token;
                    const claims = response?.data?.claims;
                    setAuth({ user, claims, token });
                }
                catch (error) {
                    localStorage.removeItem("accessToken");
                }
            }

            setLoading(false);
        };

        if (!auth.user) {
            getAuthByToken()
        }
    }, []);

    return (!loading &&
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;