import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../../components/Page';
import { Stack, IconButton, Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useReportService from '../../services/useReportService'
import useFlowService from '../../services/useFlowService'
import SmartForm from '../../components/smart-form/smart-form.component';
import SmartTable from '../../components/smart-table/smart-table';
import { SmartList } from '../../components/smart/smart';
import useNotify from '../../common/useNotify';
import LinearProgress from '@mui/material/LinearProgress';
import Iconify from '../../components/Iconify';
import { useTranslation } from "react-i18next";



const ReportEdit = () => {

  const [data, setData] = useState();
  const [fields, setFields] = useState([]);
  const [flows, setFlows] = useState([]);
  const [displayAddFilter, setDisplayAddFilter] = useState(false);
  const [filterData, setFilterData] = useState({});
  const { id } = useParams()
  const navigate = useNavigate();
  const { success, exception } = useNotify();
  const { getReport, saveReport, getFields } = useReportService();
  const { getFlows } = useFlowService();
  const { t } = useTranslation();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getFlowList();
  }, [data]);

  const isLoading = () => {

    const loading = !(data && flows.length && fields.length);

    return loading;
  }

  const getFieldList = async () => {
    // setLoading(true);

    if (!data?.flowId) {
      return;
    }
    try {
      var fieldList = await getFields(data.flowId);

      const fieldData = fieldList.data
        .map((f, index) => {
          const itemIndex = data?.fields?.indexOf(f);
          return {
            index: itemIndex > -1 ? itemIndex : index,
            selected: itemIndex > -1,
            name: f
          }
        })
        .sort((a, b) => (a.selected === b.selected ? a.index - b.index : a.selected ? -1 : 1))
        ;


      setFields(fieldData);
    }
    catch (e) {
      exception(e);
    };
  }

  const getFlowList = async () => {
    // setLoading(true);

    try {
      var flowList = await getFlows();
      setFlows(flowList.data);

      if (flowList.data.length && !data?.flowId) {
        setData(prevData => ({ ...prevData, flowId: flowList.data[0].id }));
      }

      // setLoading(!(data && flows.length && fields.length));
      getFieldList();
    }
    catch (e) {
      exception(e);
    };
  }

  const getData = async () => {
    // setLoading(true);

    if (id === "new") {
      setData({ fields: [], filters: [] });
      // setLoading(!(data && flows.length && fields.length));
    }
    else {
      try {
        var userData = await getReport(id);
        setData(userData.data);
        // setLoading(!(data && flows.length && fields.length));
      }
      catch (e) {
        exception(e);
      };
    }
  }

  const onSubmit = (formData) => {

    if (formData) {
      const postData = { ...data, name: formData.name, flowId: formData.flowId };
      console.log('postData', postData);

      save(postData);
      navigate('../report');
    }
  }

  const save = async (data) => {
    try {
      await saveReport(data);
      success('Saved.Report', 'success');
    }
    catch (e) {
      exception(e);
    };
  }

  const onCancel = () => {
    navigate(-1);
  }

  const formFields = [
    {
      dataField: 'name',
      text: t('Name'),
      xs: 6, sm: 4, md: 6,
      required: true,
    },
    {
      dataField: 'flowId',
      text: 'Flow',
      type: 'select',
      xs: 6, sm: 4, md: 4,
      required: true,
      validValues: flows.map(f => { return { value: f.id, label: f.name } })
    },
  ];

  const formButtons = [
    {
      color: "primary",
      type: "submit",
      text: t("Save")
    },
    {
      type: "reset",
      text: t("Cancel"),
      variant: "outlined"
    }
  ];

  const columnHeader = (field) => (
    <Typography variant="h6">{field.label}</Typography>
  )

  const tableFields = [
    { id: 'name', header: columnHeader, label: 'Fields', align: 'left' },
  ];

  const tableFilter = [
    { id: 'name', header: columnHeader, label: 'Fields', align: 'left' },
  ];

  const fieldListItems = fields.length > 0 && fields
    .map((field, fieldIndex) => {
      // const fieldIcon = this.getFieldIcon(field);

      return {
        key: field.name,
        text: field.name,
        selected: field.selected,
        // icon: fieldIcon,
      };
    })

  const filterListItems =
    data?.filters?.length > 0
      ? data.filters.map((filter, filterIndex) => {
        // const fieldIcon = this.getFieldIcon(field);

        return {
          key: filter.field,
          text: `${filter.field} = ${filter.value}`,
          filter: filter,
          // icon: fieldIcon,
        };
      })
      : [];

  const subForm = (
    <Grid container sx={{ mb: 4 }}>
      <Grid item xs={12} md={4}>
        <SmartList
          className="field-list"
          items={fieldListItems}
          title={t("Fields")}
          avatar={<Iconify icon="eva:list-fill" />}
          header={true}
          footer={true}
          toggle={true}
          search={false}
          pagination={false}
          toolbar={false}
          allowCollapse={false}
          allowReorder={true}
          dense={false}
          pageSize={20}
          onSelect={(item, selectedItems) => { setData((prevData => ({ ...prevData, fields: selectedItems.map(f => f.key) }))) }}
          onMove={(item, items) => { setData((prevData => ({ ...prevData, fields: items.filter(f => f.selected).map(f => f.key) }))) }}
        />
      </Grid>
      <Grid item xs={12} md={8} sx={{ pl: { md: 4 } }}>
        <SmartList
          className="field-list"
          items={filterListItems}
          title={t("Filters")}
          avatar={<Iconify icon="eva:list-fill" />}
          header={true}
          footer={true}
          footerActions={[{
            text: t('Add.Default'),
            color: "primary",
            onClick: () => setDisplayAddFilter(true),
          }]}
          search={false}
          pagination={false}
          toolbar={false}
          allowDelete={true}
          dense={false}
          pageSize={20}
          onDelete={(item, items) => { setData((prevData => ({ ...prevData, filters: items.map(i => i.filter) }))) }}
        />
      </Grid>
    </Grid>
  );


  const form = (
    <SmartForm
      data={data}
      fields={formFields}
      buttons={formButtons}
      onSubmit={(data) => onSubmit(data)}
      onCancel={onCancel}
      footer={subForm}
    >
    </SmartForm>
  );

  const filterFields = fields
    .filter(field => data.filters.find(f => f.field === field.name) === undefined)
    .map(f => { return { value: f.name, label: f.name } });

  const addFilterFields = [
    {
      dataField: 'field',
      text: t('Field'),
      type: 'select',
      xs: 6, sm: 4, md: 6,
      required: true,
      validValues: filterFields
    },
    {
      dataField: 'value',
      text: t('Value'),
      xs: 6, sm: 4, md: 4,
      required: true,
    },
  ];

  const closeAddFilterModal = (result) => {
    if (result) {
      setData((prevData => ({ ...prevData, filters: [...prevData.filters, filterData] })));
    }
    setDisplayAddFilter(false);
  }

  return (
    isLoading()
      ? <div className="loading"> <LinearProgress color="primary" /></div>
      :
      <Page title="Report">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {t("Report")}
            </Typography>
          </Stack>

          <Card>
            <Container sx={{ my: { xs: 0 }, p: { xs: 2, md: 3 } }}>
              {form}
            </Container>
          </Card>

          <Dialog
            open={displayAddFilter}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
          >
            <DialogTitle>{t("Add.Filter")}</DialogTitle>
            <DialogContent>
              <SmartForm sx={{ mt: { md: 2 } }}
                data={filterData}
                fields={addFilterFields}
                onChange={(data) => setFilterData(data)}
              >
              </SmartForm>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => closeAddFilterModal(false)} color="primary">
                {t("Cancel")}
              </Button>
              <Button onClick={() => closeAddFilterModal(true)} color="secondary" autoFocus>
                {t("Add.Default")}
              </Button>
            </DialogActions>
          </Dialog>

        </Container>
      </Page>
  );
}

export default ReportEdit;
