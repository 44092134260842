import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import useReportService from "../../services/useReportService";
import Page from '../../components/Page';
import { Stack } from '@mui/material'
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Iconify from '../../components/Iconify';
import SmartTable from '../../components/smart-table/smart-table'
import Tooltip from '@mui/material/Tooltip';
import useNotify from '../../common/useNotify';
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

const ReportList = () => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [deleteModalId, setDeleteModalId] = useState();
  const { exception } = useNotify();
  const { getReports, deleteReport } = useReportService();
  const { t } = useTranslation();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);

    try {
      var reportData = await getReports();

      setData(reportData.data);
      setLoading(false);
    }
    catch (e) {
      exception(e);
    };
  }

  const refreshList = () => {
    getData();
  }

  const closeDeleteModal = async (confirmDelete) => {

    setDeleteModalId(null);

    if (confirmDelete) {
      try {
        await deleteReport(deleteModalId);

        refreshList();
      }
      catch (e) {
        exception(e);
      }
    };
  }

  const editButton = (row) => {
    return (
      <Tooltip title={t("Edit")} >
        <IconButton component={Link} to={`${row.id}`} >
          <Iconify icon="eva:edit-fill" width={20} height={20} />
        </IconButton>
      </Tooltip>
    )
  };

  const viewButton = (row) => {
    return (
      <Tooltip title={t("View")}>
        <IconButton component={Link} to={`${row.id}/view`} >
          <Iconify icon="eva:eye-fill" width={20} height={20} />
        </IconButton>
      </Tooltip>
    )
  };

  const deleteButton = (row) => {
    return (
      <Tooltip title={t("Delete")}>
        <IconButton onClick={() => setDeleteModalId(row.id)} >
          <Iconify icon="eva:close-circle-fill" width={20} height={20} />
        </IconButton>
      </Tooltip>
    )
  };


  const tableFields = [
    { id: 'name', header: t('Name') },
    { id: 'createdAt', header: t('Created.Default'), data: (row, field) => format(new Date(field), 'dd/MM/yyyy') },
    { id: 'updatedAt', header: t('Updated.Default'), data: (row, field) => format(new Date(field), 'dd/MM/yyyy') },
    { data: editButton },
    { data: viewButton },
    { data: deleteButton },
  ];

  return (

    loading
      ? <div className="loading"> <LinearProgress color="primary" /></div>
      :
      <Page title="Report">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {t("Reports")}
            </Typography>
            <Button variant="contained" component={Link} to="new" startIcon={<Iconify icon="eva:plus-fill" />}>
              {t("New")}
            </Button>
          </Stack>

          <Card>
            <SmartTable
              fields={tableFields}
              rows={data}
            />
          </Card>

          <Dialog
            open={deleteModalId ? true : false}
            onClose={() => closeDeleteModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{t("AlertDialog.Title")}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t('AlertDialog.Delete.Report')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => closeDeleteModal(false)} color="primary">
                {t("Cancel")}
              </Button>
              <Button onClick={() => closeDeleteModal(true)} color="secondary" autoFocus>
                {t("Delete")}
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Page>
  )
}

export default ReportList;
