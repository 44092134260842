import { useSnackbar } from 'notistack';

const useNotify = () => {

  const { enqueueSnackbar } = useSnackbar();

  const notify = (message, variant) => {
    enqueueSnackbar(message, { variant })
  }

  const success = (message) => {
    enqueueSnackbar(message, { variant: 'success' })
  }

  const error = (message) => {
    enqueueSnackbar(message, { variant: 'error' })
  }

  const warning = (message) => {
    enqueueSnackbar(message, { variant: 'warning' })
  }

  const info = (message) => {
    enqueueSnackbar(message, { variant: 'info' })
  }

  const exception = (exception) => {
    let message = exception.response?.data?.title || exception.response?.data?.message || exception.response?.statusText || "Error";
    const errors = exception.response?.data?.errors;
    if (errors && Object.keys(errors).length) {
      message = Object.keys(errors).map(k => `${k}: ${errors[k]}`).join('\n');
    }
    enqueueSnackbar(message, { variant: 'error' })

    console.error(exception);
  }

  return { notify, success, error, warning, info, exception }
}

export default useNotify;